import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Container, Resources, deviceMin } from "../components/Primitives"
import meta from "../../content/data/meta.json"
import ResourceHero from "../components/Resources/resourceHero"
import Services from "../components/Resources/sevices"
import styled from "styled-components"
import service from "../images/service.png"
import "../components/layout.css"
import style from "swiper/swiper-bundle.css"
console.log(style)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleDoubleDown,
  faChalkboardTeacher,
  faHeadset,
  faSearchPlus,
  faUserCheck,
  faUserGraduate,
  faUserPlus,
  faUsers
} from "@fortawesome/free-solid-svg-icons"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Parallax,
  Navigation
} from "swiper"
const ServicePage = ({ data }) => {
  SwiperCore.use([EffectCoverflow, Pagination, Parallax, Navigation])
  return (
    <Layout style={{ backgroundColor: "#F9E7E7" }}>
      <SEO
        pathName="/our-services"
        title="Our Services"
        // keywords={keywords}
      />
      <div style={{ backgroundColor: "#FFEBF4" }}>
        <ResourceHero
          color="rgb(184 133 142 / 52%)"
          title="Our Services"
          subTitle="We take the time to develop strong working relationships with our clients by understanding their business, their needs, and the company culture. With that understanding and with the knowledge and expertise gained from over the years of experience in human resources and business, we recommend solutions that align with business priorities and then support clients in implementation"
          image={service}
        />

        <H1_Attn id="about">for Employers</H1_Attn>
        <div className="feat bg-gray pt-5 pb-5">
          <div className="container">
            <div className="row" style={{ placeContent: "center" }}>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_one">
                    <FontAwesomeIcon icon={faUserPlus} />
                  </span>
                  <h6>Manpower Recruitment & Outsourcing</h6>
                  <p>
                    <b>Career Ladder</b> recognizes the need for the{" "}
                    <b>“right fit for the right job at the right time”</b> that
                    every company demands.As your staffing partner, we recruit
                    winning teams for you in the fastest way.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_two">
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </span>
                  <h6>Executive Search</h6>
                  <p>
                    <b>Career Ladder</b> partners with companies and complies with the
                    supply of critical business positions at all levels of the
                    hierarchy.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_three">
                    <FontAwesomeIcon icon={faUsers} />
                  </span>
                  <h6>Bulk Hiring</h6>
                  <p>
                    <b>Career Ladder</b> has the ability to hire in bulk where the
                    organization wants to employ candidates in large scale in a
                    short time.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_four">
                    <FontAwesomeIcon icon={faUserGraduate} />
                  </span>
                  <h6>Talent Pipelining</h6>
                  <p>
                    <b>Career Ladder</b> exercising a strategic rather than reactive
                    approach, as well as ensuring a higher calibre of talent.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_five">
                    <FontAwesomeIcon icon={faHeadset} />
                  </span>
                  <h6>Video Interviews</h6>
                  <p>
                    When time is money, gathering a sense of the individual
                    through the video can help you save time, reduce recruitment
                    costs so it is a Win Win situation for employers and
                    candidates.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_six">
                    <FontAwesomeIcon icon={faUserCheck} />
                  </span>
                  <h6>Reference Checks</h6>
                  <p>
                    Is he the right fit for your team? Find out through
                    reference checks to validate your decisions & offers.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <span className="icon feature_box_col_six">
                    <FontAwesomeIcon icon={faChalkboardTeacher} />
                  </span>
                  <h6>Provide Trainers</h6>
                  <p>
                    <b>Career Ladder</b> have skilled and professional trainers
                    to conduct In house training sessions on soft skills and
                    technical training.
                  </p>
                </div>
              </div>
            </div>
            <div className="row" style={{ placeContent: "center" }}>
              <FontAwesomeIcon size="5x" icon={faAngleDoubleDown} />
            </div>
            <div className="row mt-4" style={{ placeContent: "center" }}>
              <h2 style={{color: '#85083e'}}>Other HR Operations</h2>
            </div>
            <div className="trainers-swiper">
              <Swiper
                initialSlide={2}
                loop={true}
                speed={800}
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={4}
                pagination={{ clickable: true }}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true
                }}
                parallax={true}
                navigation
                breakpoints={{
                  1180: {
                    slidesPerView: 2
                  },
                  760: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    slidesPerColumn: 1
                  },
                  375: {

                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  }
                }}
              >
                <SwiperSlide
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(0 0 0 / 86%), rgb(87 123 140 / 29%)),url(https://image.freepik.com/free-photo/business-man-insurance-application-form-concept_53876-51.jpg)"
                  }}
                >
                  <img
                    src="https://image.freepik.com/free-photo/business-man-insurance-application-form-concept_53876-51.jpg"
                    className="entity-img"
                  />
                  <div className="content">
                    <p
                      className="title"
                      data-swiper-parallax="-30%"
                      data-swiper-parallax-scale=".7"
                    >
                      Design Forms for Companies
                    </p>
                    <span className="caption" data-swiper-parallax="-20%">
                      Business forms are the backbone of business operations.
                      <b>Career Ladder</b> provides you with a great opportunity to
                      present a polished and unified company image.
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(0 0 0 / 86%), rgb(87 123 140 / 29%)),url(https://www.lpcentre.com/wp-content/uploads/2019/05/istock-621493052.jpg)"
                  }}
                >
                  <img
                    src="https://www.lpcentre.com/wp-content/uploads/2019/05/istock-621493052.jpg"
                    className="entity-img"
                  />
                  <div className="content">
                    <p
                      className="title"
                      data-swiper-parallax="-30%"
                      data-swiper-parallax-scale=".7"
                    >
                      HR Policies and Procedures
                    </p>
                    <span className="caption" data-swiper-parallax="-20%">
                      Policies and procedures together gives employees a
                      well-rounded view of culture that the organization is
                      striving for, what behavior is expected of them and how to
                      achieve both of these.
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(0 0 0 / 86%), rgb(87 123 140 / 29%)),url(https://solutionsatwork.com.sg/wp-content/uploads/Job-and-Process-Redesign.png)"
                  }}
                >
                  <img
                    src="https://solutionsatwork.com.sg/wp-content/uploads/Job-and-Process-Redesign.png"
                    className="entity-img"
                  />
                  <div className="content">
                    <p
                      className="title"
                      data-swiper-parallax="-30%"
                      data-swiper-parallax-scale=".7"
                    >
                      Process Redesign / Hierarchy
                    </p>
                    <span className="caption" data-swiper-parallax="-20%">
                      <b>Career Ladder</b> gives you a fine view of your Organizational
                      hierarchy. It is a pyramid that provides a company a clear
                      structure of different levels of authorities.
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(0 0 0 / 86%), rgb(87 123 140 / 29%)),url(https://img.franchising.com/art/articles/4107_plate.jpg"
                  }}
                >
                  <img
                    src="https://img.franchising.com/art/articles/4107_plate.jpg"
                    className="entity-img"
                  />
                  <div className="content">
                    <p
                      className="title"
                      data-swiper-parallax="-30%"
                      data-swiper-parallax-scale=".7"
                    >
                      Performance Management Form
                    </p>
                    <span className="caption" data-swiper-parallax="-20%">
                      <b>Career Ladder</b> builds your PMS on your behalf.Performance
                      reviews that are used to support decisions related to
                      training and career development, compensation or
                      termination.
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, rgb(0 0 0 / 86%), rgb(87 123 140 / 29%)),url(https://www.careeraddict.com/uploads/article/50523/Job_20Descriptions._20Card_20File_20on_20Background_20of_20White_20Modern_20Computer_20Keypad_202.jpg)"
                  }}
                >
                  <img
                    src="https://www.careeraddict.com/uploads/article/50523/Job_20Descriptions._20Card_20File_20on_20Background_20of_20White_20Modern_20Computer_20Keypad_202.jpg"
                    className="entity-img"
                  />
                  <div className="content">
                    <p
                      className="title"
                      data-swiper-parallax="-30%"
                      data-swiper-parallax-scale=".7"
                    >
                      Job Description
                    </p>
                    <span className="caption" data-swiper-parallax="-20%">
                      <b>Career Ladder</b> will work for you as a remote HR for your
                      company and provide you a brief job description of all
                      levels of employees.
                    </span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <H1_Attn id="about">for Job Seekers</H1_Attn>
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <figure className="snip1174 darkpink">
              <img
                style={{ width: "100%" }}
                src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=520&q=80"
                alt="sq-sample33"
              />
              <figcaption>
                <h2>Get Recruited</h2>
                <p>
                  Whether you are a fresh graduate or an experienced
                  professional , <b>Career Ladder</b> is the solution to find the right
                  company for you.
                </p>
              </figcaption>
            </figure>
            <figure className="snip1174 violetred">
              <img
                style={{ width: "100%" }}
                src="https://images.unsplash.com/photo-1573496130407-57329f01f769?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                alt="sq-sample33"
              />
              <figcaption>
                <h2>Guerilla Cv - Fastest way to get Interview Calls</h2>
                <p>
                  <b>Career Ladder</b> CV Writing Service will ensure that your CV
                  showcases your skills and your qualities and experiences, and
                  your abilities – whether you are just starting out in your
                  career or embarking on a new phase of your career or changing
                  industries.
                </p>
              </figcaption>
            </figure>
          </div>
          <div className="row mb-5" style={{ justifyContent: "center" }}>
            <figure className="snip1174 rose">
              <img
                style={{ width: "100%" }}
                src="https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"
                alt="sq-sample34"
              />
              <figcaption>
                <h2>Revamp Linkedin Profile</h2>
                <p>
                  Your updated social media accounts enhance your chances of
                  selection. <b>Career Ladder</b> has the skills to upgrade your social
                  account to improve your visibility on professional platforms.
                </p>
              </figcaption>
            </figure>
            <figure className="snip1174 redpurple">
              <img
                style={{ width: "100%", height: "100%" }}
                src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                alt="sq-sample34"
              />
              <figcaption>
                <h2>Interview Preparation</h2>
                <p>
                  <strong>Getting nervous for the interview?</strong> <b>Career
                  Ladder</b> has a solution to boost up your confidence for an interview by conducting mock sessions.
                </p>
              </figcaption>
            </figure>
          </div>
          {/* </Container> */}
        </div>
      </div>
    </Layout>
  )
}

export default ServicePage

const H1_Attn = styled.h1`
  text-align: center;
  color: #85083e;
  margin: 50px 0;
  text-transform: uppercase;
`
const Div = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 70%;
  }
`
