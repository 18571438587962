import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { deviceMax, deviceMin, GreenButton } from "../Primitives"

interface Props {
  title: string
  body: String
  color: string
}

const Services = (props: Props) => {
  return (
    <Card style={{ backgroundColor: props.color }}>
      <div style={{ height: "80px" }}>
        <h3 className="text-center" style={{ color: "black" }}>
          {props.title}
        </h3>
      </div>

      <p className="text-center" style={{ color: "black" }}>
        {props.body}
      </p>
    </Card>
  )
}

export default Services

const Card = styled.div`
  box-sizing: border-box;
  text-decoration: none;
  margin-bottom: 50px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 1.5%;
  @media ${deviceMin.mobileS} {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media ${deviceMin.tablet} {
    flex: 0 0 50%;
    max-width: 40%;
  }

  @media ${deviceMin.laptop} {
    flex: 0 0 33.333333%;
    max-width: 28.333333%;
  }
`

const Content = styled.p`
  margin-bottom: 30px;

  /* @media ${deviceMin.mobileS} {
    text-align: justify;
  } */
`
// const Title = styled.h2`
//   @media ${deviceMin.mobileS} {
//     text-align: center;
//   }
// `
const Category = styled.small`
  margin-bottom: 15px;
`

const Source = styled.small`
  margin-bottom: 15px;
`
const SeeMoreResource = styled(Link)`
  font-size: 16px;
  border: 1px solid var(--green);
  padding: 8px 14px;
  border-radius: 24px;
  color: white;
  background-color: var(--green);
  box-shadow: var(--boxShadow);
  transition: all 0.25s linear;

  &:hover {
    color: white;
    box-shadow: var(--boxShadowHover);
    transform: var(--transform);
  }
`
